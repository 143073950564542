.main-tab-container {
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(4, 28, 34, 0.1);
	border-radius: 10px;
	padding: 20px;
}

.credit-tab-container {
	border: 1px solid rgba(4, 28, 34, 0.1);
	border-radius: 10px;
	padding: 20px;
}