.stepper-tooltip {
	position: relative;
	display: flex;
	justify-content: center;
	min-width: 120px;
	padding-left: 6px;
	padding-right: 6px;
	border-radius: 6px;
  }
  
.stepper-tooltip-label {
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	z-index: 1;
	left: 50%;
  }
  
   /* .topStepperArrow::after {
	content: "";
	position: absolute;
	top: 100%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #ccc transparent transparent transparent;
  }
   .bottomStepperArrow::before {
	content: "";
	position: absolute;
	top: -20%;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #ccc transparent;
  } */