.shipping-address-card {
	border: 1px solid #e0e0e0;
	border-radius: 10px;
	padding-right: 8px;
	padding-left: 8px;
	padding-bottom: 6px;
}
.shipping-address-card:hover {
	border: 1px solid var(--primary-color-opacity)
}

.edit-transcription {
	position: absolute;
	top: -10px;
	right: 28px;
	background-color: var(--white);
	text-align: center;
	width: 150px;
}

.credit-disabled-form {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color:rgba(0,0,0,.03);
	border-radius: 10px;
}

.disabled-form {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.03);
	padding: 50px;
	border-radius: 10px;
}