.clickable {
	cursor: pointer;
}

.hide {
	display: none;
}
  
.show {
	display: block;
}

.flex {
	display: flex;
}

.just-start {
	justify-content: flex-start;
}

.just-center {
	justify-content: center;
}

.just-end {
	justify-content: flex-end;
}

.just-btwn {
	justify-content: space-between;
}

.ali-start {
	align-items: flex-start;
}

.ali-center {
	align-items: center;
}

.ali-end {
	align-items: flex-end;
}

.dir-col {
	flex-direction: column;
}

.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}

.date-font {
	font-size: 14px;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.borderBottom {
	border-bottom: 1px solid var(--divider-color);
	padding-bottom: 2px;
}