.sun-editor-editable > p {
	font-family: 'Work Sans';
	font-size: 1rem;
}

.se-custom-variable {
	border: 1px solid #3a5464;
	background: #c8e2f1;
	box-shadow: 1px 1px 2px #b3d0e2;
	border-radius: 6px;
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	padding-left: 6px !important;
	padding-right: 6px !important;
	margin-left: 3px;
	margin-right: 3px;
}

.se-resizing-bar {
	border: none !important;
	min-height: 0 !important;
	height: 0 !important;
	background: none !important;
}