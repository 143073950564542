.dashboard-tab {
	margin-left: -4px;
	margin-right: -4px;
	padding-left: 20px;
	padding-right: 20px;
}

.dashboard-tab:first-child {
	margin-left: 0;
	border-radius: 14px 0 0 0;
}

.dashboard-tab:last-child {
	border-radius: 0 14px 0 0;
}