 .right-drawer-container {
	width: 100%;
	height: 100%;
	background-color: var(--white);
	display:flex;
	justify-content: center;
}

.standard-margin-container {
	width: 94%;
	flex-grow: 1;
	margin-left: 0.8%;
}

.right-drawer-el-container {
	width: 100%;
	height: calc(100vh -  78px);
	display: flex;
	flex-direction: column;
	align-items: center;
}


.right-drawer-header {
	position: sticky;
	top: 0;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 80px;
	background-color: var(--white);
	z-index: 1501;
	border-bottom: 1px solid var(--divider-color);
}

.header-container {
	width: 100%;
	height: 80px;
	
	padding-left: 2%;
	padding-right: 2.5%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.vertical-standard-margin {
	margin-top: 10px;
}

.task-footer {
	width: 100%;
	height: 79px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 2%;
	padding-right: 2.5%;
	border-top: 1px solid var(--divider-color);
}

.standard-tasks-height {
height: calc(100vh - 267px)
}
.standard-notes-height {
height: calc(100vh - 216px)
}
.edited-notes-height {
height: calc(100vh - 267px)
}

.create-note-input {
	width: 98%;
	font-family: 'Work Sans';
	font-size: 1rem;
	line-height: 2.2rem;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.23);
}

.create-note-input:hover {
	border: 1px solid var(--primary-color);
	outline: none;
}
.create-note-input:focus,
.create-note-input:focus-visible {
	outline: 1px solid var(--primary-color);

}

.popup-container {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
}

.hide-subject-input {
	background-color: var(--white);
	-webkit-text-fill-color: var(--grey-label);
}
.hide-subject-input > div > div > div {
	display: none;
}
.hide-subject-input > div > div > fieldset {
	border: none;
}
.hide-subject-input > div > div > input {
	cursor: default;
	-webkit-text-fill-color: var(--primary-color) !important;
	padding-left: 12px !important;
}

.hide-section-input > fieldset {
	border: none;
}

.hide-section-input > div {
	-webkit-text-fill-color: var(--primary-color) !important;
}

.hide-section-input > svg {
	display: none;
}

.task-tab-ticker {
	width: 18px;
	height: 18px;
	font-size: 12px;
}
  