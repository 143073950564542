.notistack-SnackbarContainer {
	z-index: 2100!important;
}
.notistack-Snackbar {
	z-index: 2100!important;
}

.notistack-MuiContent {
	border-top: 4px solid!important;
}

.notistack-MuiContent-info {
	border-top-color: #7DEBF5!important;
	background-color: #EDF8F9!important;
	color: var(--primary-color)!important;
}
.notistack-MuiContent-success {
	border-top-color: #49EBAA!important;
	background-color: #D2FAEA!important;
	color: var(--primary-color)!important;
}

.notistack-MuiContent-warning {
	border-top-color: #FF966F!important;
	background-color: #FFE5DB!important;
	color: var(--primary-color)!important;
}

.notistack-MuiContent-error {
	border-top-color: #FF4351!important;
	background-color: #F9969B!important;
	color: var(--primary-color)!important;
}