.building-target-count {
	display: flex;
	align-items: center;
	width: 450px;
	height: 100px;
	background-color: #EDE7F6;
	margin-right: 20px;
	border-radius: 8px;
	padding-left: 36px;
	padding-right: 36px;
}

.flux-target-count {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	margin-left: 16px;
	background-color: #EDE7F6;
	border-radius: 8px;
	padding-left: 36px;
	padding-right: 36px;
}

.target-selector-footer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	position: sticky;
	bottom: 0;
	padding-top: 16px;
	padding-bottom: 16px;
	background-color: var(--white);
}
