.partner-card {
	border-radius: 10px;
	padding: 15px;
	background-color: var(--white);
	box-shadow: 0 3px 3px -2px rgba(0,0,0,.2);
}

.partner-document {
	border-bottom: 1px solid rgba(0,0,0,.2);
}

.partner-document:last-child {
	border-bottom: none;
}

.partner-logo {
	height: 100%;
	width: 100%;
	object-fit: contain;
}