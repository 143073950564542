.simple-data {
	color: var(--primary-color);
}

.important-data {
	color: var(--primary-color);
	font-weight: 500;
}

.link-data {
	color: var(--primary-color);
	font-weight: 600;
	text-decoration: none;
}