.MuiAppBar-root {
	z-index: 1502;
}

.MuiSelect-select > .MuiBox-root {
	font-size: 14px;
}

.MuiPopover-paper {
	border-radius: 11px;
}