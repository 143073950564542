.single-contract:hover {
	background-color: #041C220A;
}

.contract-view-container {
	background-color: #041C220A;
	display: flex;
	justify-content: center;
}

.contract-container {
	width: 92%;
	margin-top: 70px;
	height: 90%;
}

.contract-version {
	min-height: 80px;
	border-radius: 0 0 10px 10px;
	border-top: 4px solid #84a3aa;

}