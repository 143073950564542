.single-note {
	width: 100%;
	position: relative;
	border: 1px solid var(--divider-color);
	border-radius: 10px;
	padding: 10px;
}

.single-note:hover {
	border: 1px solid var(--primary-color);
}
.single-note > .modify, .separator, .delete {
	display: none;
}

.single-note >.modify {
	display: none;
	position: absolute;
	right: 68px;
	top: 8px;
}

.single-note >.save {
	display: none;
	position: absolute;
	right: 68px;
	top: 8px;
}

.single-note >.undo {
	display: none;
	position: absolute;
	right: 10px;
	top: 8px;
}

.single-note >.delete {
	position: absolute;
	display:none;
	right: 10px;
	top: 8px;
}

.single-note >.separator0 {
	display: none;
	position: absolute;
	right: 58px;
	top: 8px;
	cursor: default;
}

.single-note >.separator1 {
	display: none;
	position: absolute;
	right: 58px;
	top: 8px;
	cursor: default;
}

.single-note >.separator2 {
	display: none;
	position: absolute;
	right: 115px;
	top: 8px;
	cursor: default;
}

.single-note:hover >.date {
	display: none;
}

.single-note:hover >.modify,
.single-note:hover >.save,
.single-note:hover >.delete,
.single-note:hover >.undo, 
.single-note:hover >.separator0, 
.single-note:hover >.separator1, 
.single-note:hover >.separator2 {
	display: block;
}