html {
	overflow: hidden;
}
:root {
	--primary-color: #094754;
	--primary-color-opacity: #09475480;
	--divider-color:#041C221A;
	--white: #FFFFFF;
	--white-darker: #f8f8f8;
	--grey-label: #8c8c8c;
}

body {
	padding-right: 0!important;
	overflow: unset!important;
	overscroll-behavior: none;
}

.page-container {
	width: 94%;
	max-width: 1280px;
	position: absolute;
	left: 36px;
	margin-top: 30px;
	padding-bottom: 30px;
}

.container {
	min-height: 600px;
	max-width: 1280px;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 90%;
	position: relative;
	left: 50px;
	background-color: var(--white);
}

.table-container {
	position: absolute;
	width: 100%;
	padding-top: 14px;
	padding-bottom: 4px;
	padding-right: 30px;
	padding-left: 30px;
	background-color: var(--white);
	z-index: 19;
}
.reduced-table-container {
	width: 100%;
	height: calc(100vh - 190px);
	padding-top: 14px;
	padding-bottom: 4px;
	padding-right: 30px;
	padding-left: 30px;
	background-color: var(--white);
}

.side-review-header {
	display: flex;
	align-items: center;
	height: 49px;
	background-color: var(--primary-color);
	border-top: 4px solid #F0FF32;
}

.modal-container {
	width: 100%;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 12px;
}

.MuiPopover-paper {
	box-shadow: 1px 3px 5px 0 var(--divider-color);
}

.logo-preview {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.grey-divider {
	height: 1px;
	background-color: var(--divider-color);
	margin-top: 24px;
	margin-bottom: 24px;
}

/* .MuiTooltip-popper {
	z-index: 1500!important;
} */