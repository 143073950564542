.disabled-input {
	background-color: var(--white-darker);
	-webkit-text-fill-color: var(--grey-label);
	
	> .MuiFormLabel-root {
		font-weight: 500;
	}

	 > .MuiFormControl-root > .MuiFormLabel-root {
		font-weight: 500;
	}

	> .MuiOutlinedInput-root .Mui-disabled {
		-webkit-text-fill-color: var(--grey-label)!important;
		background-color: var(--white-darker);
	}
  }

input:disabled {
	cursor: not-allowed;
}

.hidden-input {
	width: 100%;
	border: none;
	background: transparent;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 12px;
	font-size: 16px;
	border-radius: 4px;
}

.hidden-input:focus {
	outline: 1px solid var(--grey-label);
}