.notification-container {
	border: 1px solid var(--divider-color);
	border-radius: 10px;
}
.single-notification {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--divider-color);
}
.single-notification:last-child {
	border-bottom: none;
}

.notificationDescription::first-letter {
	text-transform: capitalize;
}

.read-circle {
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: #FF4351;
}