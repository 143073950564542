.rdrDayToday .rdrDayNumber span:after {
	background-color: var(--white) !important;
}

.rdrInputRanges {
	display: none;
}

.rdrDayNumber {
	font-weight: 600;
}