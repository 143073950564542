.simplebar-scrollbar::before {
	background-color: #e5e5e5;
	border-radius: 20px;
	width: 5px;
}

::-webkit-scrollbar {
	background-color: var(--white);
	width: 5px;
}
.MuiTableContainer-root::-webkit-scrollbar-track {
	margin-top: 105px;
}

::-webkit-scrollbar-thumb {
	background-color: #cacaca;
	border-radius: 20px;
}