.drag-n-drop-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 170px;
	border: 1px dashed var(--primary-color-opacity);
	margin-top: 10px;
	margin-bottom: 20px;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
}

.small-drag-n-drop-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 80;
	border: 1px dashed var(--primary-color-opacity);
}