.log-notification {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--divider-color);
	padding-top: 8px;
	padding-bottom: 8px;
	cursor: default;
}

.log-notification:last-child {
	border-bottom: none;
}

.log-notification:hover {
	background-color: var(--divider-color);
}