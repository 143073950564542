.tachograph {
	position:absolute;
	width: 90%;
	height: 240px;
	top: 50px;
	border-radius: 100%;
	border: 50px solid #16C399;
}

.needle {
	width: 5px;
	height: 100px;
	background: #525252;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-left-radius: 100%;
	border-top-right-radius: 100%;
	display: inline-block;
	left: 0;
	right: 0;
	position: absolute;
	top: 50px;
	bottom: 0;
	margin: -20px auto auto auto;
	animation: move 7s infinite;
	-webkit-animation: move 7s infinite;
	transform-origin: bottom;
	}